<template>
  <div class="app-wrap">
    <div class="flex-center-image ">
      <img src="@/assets/CDG.png" />
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.flex-center-image {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}
</style>
